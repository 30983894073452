<script setup>
import {
  reactive, ref, getCurrentInstance, onMounted,
} from 'vue';
import dayjs from 'dayjs';

const { suppliersId } = JSON.parse(localStorage.getItem('userInfo'));
const app = getCurrentInstance().proxy;

const options = [
  { value: 1, label: '正常合作中' },
  { value: 2, label: '已终止合作' },
  { value: 3, label: '合作已到期' },
  { value: 4, label: '合作将到期' },
];

const search = {
  supplierId: '',
  supplierName: '',
  states: '',
  timeList: [],
  currentPage: 1,
  nowPageSize: 10,
  count: 0,
};
const params = reactive({ ...search });

const loading = ref(false);
const tableData = ref([]);
const btnLoading = ref(false);

async function operation(type, row) {
  const path = '/supplyChain/cooperation/detail';
  if (type === 'add') {
    btnLoading.value = true;
    const res = await app.$axios.get(app.$api.refund.addressList);
    btnLoading.value = false;
    if (res.code !== 0) return;
    if (!res.data || !res.data.length) {
      app.$confirm('请先设置退货地址?', '提示', {
        confirmButtonText: '去设置',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        app.$router.push('/nb/order/addressManagement/list');
      }).catch(() => {});
      return;
    }
  }
  app.$router.push({
    path,
    query: {
      type,
      suppliersId: row ? row.suppliersId : suppliersId,
    },
  });
}

function dayFormat(val) {
  return val ? dayjs(+val).format('YYYY/MM/DD HH:mm:ss') : '';
}
async function getList(num) {
  if (num) params.currentPage = num;
  loading.value = true;
  const res = await app.$axios.post(app.$api.preferred_supplychain.list, {
    currentPage: params.currentPage,
    pageSize: params.nowPageSize,
  });
  loading.value = false;
  if (res.code === 0) {
    tableData.value = res.data.records;
  }
}

onMounted(() => {
  getList(1);
});
</script>

<template>
  <div class="outer supply-chain">
    <el-alert
      class="margin-b10"
      type="success"
      effect="light"
      :closable="false"
    >
      <div
        class="wd-flex wd-flex-between wd-flex-align-center"
      >
        <div>
          <i
            class="el-icon-message-solid"
          ></i>欢迎来到农博供应链模块，加入农博供应链，获取更多商业机会~
        </div>

        <el-button
          v-if="!tableData.length"
          :loading="btnLoading"
          size="mini"
          type="primary"
          @click="operation('add')"
        >
          申请供应链合作
        </el-button>
      </div>
    </el-alert>

    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="tableData"
      class="tableBox"
      border
      element-loading-text="拼命加载中"
      style="width: 100%"
      row-key="id"
      :header-cell-style="{
        background: '#f5f7fa',
      }"
    >
      <el-table-column
        prop="suppliersName"
        label="名称"
      />
      <el-table-column
        prop="contactPersonName"
        label="联系人姓名"
      />
      <el-table-column
        prop="contactPersonMobile"
        label="联系人手机"
      />
      <el-table-column
        prop="cooperationStatusName"
        label="合作状态"
      />
      <el-table-column
        label="申请时间"
      >
        <template slot-scope="{row}">
          {{ dayFormat(row.applyTime) }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="100"
      >
        <template
          slot-scope="{row}"
        >
          <el-link
            class="margin-r10"
            type="primary"
            @click="operation('show', row)"
          >
            查看
          </el-link>
          <el-link
            v-if="[2].includes(+row.cooperationStatus)"
            class="margin-r10"
            type="primary"
            @click="operation('edit', row)"
          >
            编辑
          </el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style scoped lang="scss">
.tableBox{
  :deep(.el-table__header){
    .el-table__cell{
      font-weight: bold;
      color: #333;
    }
  }
  :deep(.el-table__row){
    font-size: 12px;
  }
}
.supply-chain {
  :deep(.el-alert__content) {
    width: 100%;
  }
  :deep(.el-alert__description) {
    margin: 0;
    // min-height: 20px;
  }
}
</style>
