<script setup>
import Excellence from './components/excellence.vue';
import Supply from './components/supply.vue';

const { isMemberStore } = JSON.parse(localStorage.getItem('userInfo'));
// const isMemberStore = false;
</script>

<template>
  <Excellence v-if="isMemberStore" />
  <Supply v-else />
</template>
