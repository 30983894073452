<script setup>
import {
  reactive, ref, getCurrentInstance, onMounted,
} from 'vue';
import dayjs from 'dayjs';

const app = getCurrentInstance().proxy;
const options = [
  { value: 0, label: '申请中' },
  { value: 1, label: '合作中' },
  { value: 2, label: '已拒绝' },
  { value: 3, label: '已终止' },
];

const search = {
  contactPersonName: '',
  suppliersName: '',
  contactPersonMobile: '',
  cooperationStatus: '',
  timeList: [],
  currentPage: 1,
  pageSize: 10,
  count: 0,
};
const params = reactive({ ...search });

const loading = ref(false);
const tableData = ref([]);

async function operation(type, row) {
  if (type === 'reset') {
    for (const key in search) {
      params[key] = search[key];
    }
    getList();
  } else if (type === 'open' || type === 'stop') {
    let message = type === 'open' ? '是否确认开启合作？' : '是否确认终止合作？';
    if (type === 'stop') {
      const res = await app.$axios.post(app.$api.supplychain.unRelationTip, {
        relationSupplierId: row.suppliersId,
      });
      if (res.code === 0 && res.data && res.data.length) {
        message = `是否确认终止合作，终止合作后如不出售该供应商商品，请手动将以下商品从优选店商品列表下架，商品ID：${res.data}`;
      }
    }
    app.$confirm(message, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(async () => {
      const res = await app.$axios.post(app.$api.preferred_supplychain.cooperateStatus, {
        id: row.id,
        cooperateStatus: type === 'open' ? 1 : 2,
      });
      if (res.code !== 0) return;
      app.$message.success('操作成功！');
      getList();
    }).catch(() => {});
  } else {
    const path = '/supplyChain/cooperation/detail';
    app.$router.push({
      path,
      query: {
        type,
        suppliersId: row.suppliersId,
      },
    });
  }
}
async function deleteSumit(row) {
  const res = await app.$axios.get(app.$api.settlement.blacklist_delete, {
    params: {
      id: row.id,
    },
  });
  if (res.code === 0) {
    app.$message.success('操作成功！');
    getList();
  }
}
function dayFormat(val) {
  return val ? dayjs(+val).format('YYYY/MM/DD HH:mm:ss') : '';
}
// 分页控制
function handleSizeChange(val) {
  params.pageSize = val;
  params.currentPage = 1;
  getList();
}
function handleCurrentChange(val) {
  params.currentPage = val;
  getList();
}
async function getList(num) {
  if (num) params.currentPage = num;
  loading.value = true;
  const res = await app.$axios.post(app.$api.preferred_supplychain.list, {
    ...params,
    startTime: params.timeList ? params.timeList[0] : undefined,
    endTime: params.timeList ? params.timeList[1] : undefined,
  });
  loading.value = false;
  if (res.code === 0) {
    tableData.value = res.data.records;
    params.count = Number(res.data.total);
  }
}

onMounted(() => {
  getList(1);
});
</script>

<template>
  <div class="outer supply-chain">
    <search-panel
      show-btn
      flex
      @getList="getList(1)"
    >
      <div>
        <p>供应商名称</p>
        <el-input
          v-model="params.suppliersName"
          clearable
          placeholder="请输入供应商名称"
          size="mini"
          @clear="getList(1)"
        />
      </div>
      <div>
        <p>联系人姓名</p>
        <el-input
          v-model="params.contactPersonName"
          clearable
          placeholder="请输入联系人姓名"
          size="mini"
          @clear="getList(1)"
        />
      </div>
      <div>
        <p>联系人手机</p>
        <el-input
          v-model="params.contactPersonMobile"
          clearable
          placeholder="请输入联系人手机"
          size="mini"
          @clear="getList(1)"
        />
      </div>
      <div>
        <p>合作状态</p>
        <el-select
          v-model="params.cooperationStatus"
          placeholder="请选择合作状态"
          size="mini"
          clearable
          @change="getList(1)"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div>
        <p>申请时间</p>
        <el-date-picker
          v-model="params.timeList"
          size="mini"
          type="datetimerange"
          align="right"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          value-format="timestamp"
          @change="getList(1)"
        />
      </div>
      <el-button
        slot="button-right"
        size="mini"
        @click="operation('reset')"
      >
        重 置
      </el-button>
    </search-panel>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="tableData"
      class="tableBox"
      border
      element-loading-text="拼命加载中"
      style="width: 100%"
      row-key="id"
      :header-cell-style="{
        background: '#f5f7fa',
      }"
    >
      <el-table-column
        prop="suppliersId"
        label="供应商ID"
      />
      <el-table-column
        prop="suppliersName"
        label="供应商名称"
      />
      <el-table-column
        prop="contactPersonName"
        label="联系人姓名"
      />
      <el-table-column
        prop="contactPersonMobile"
        label="联系人手机"
      />
      <el-table-column
        prop="cooperationStatusName"
        label="合作状态"
      />
      <el-table-column
        label="申请时间"
      >
        <template slot-scope="{row}">
          {{ dayFormat(row.applyTime) }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
      >
        <template
          slot-scope="{row}"
        >
          <el-link
            class="margin-r10"
            type="primary"
            @click="operation('show', row)"
          >
            查看
          </el-link>
          <el-link
            v-if="+row.cooperationStatus === 0"
            class="margin-r10"
            type="primary"
            @click="operation('audit', row)"
          >
            审核
          </el-link>
          <el-link
            v-if="+row.cooperationStatus === 3"
            type="primary"
            @click="operation('open', row)"
          >
            开启合作
          </el-link>
          <el-link
            v-if="+row.cooperationStatus === 1"
            type="danger"
            @click="operation('stop', row)"
          >
            终止合作
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData && tableData.length > 0"
      :current-page="params.currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="params.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="params.count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<style scoped lang="scss">
.tableBox{
  :deep(.el-table__header){
    .el-table__cell{
      font-weight: bold;
      color: #333;
    }
  }
  :deep(.el-table__row){
    font-size: 12px;
  }
}
.supply-chain {
  :deep(.el-alert__content) {
    width: 100%;
  }
  :deep(.el-alert__description) {
    margin: 0
  }
}
</style>
